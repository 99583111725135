import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/landing';

import CustomerReviews from 'components/content-blocks/customer-reviews/customer-reviews';
import FeaturesGrid from 'components/content-blocks/features-grid';
import MobileFeatures from 'components/content-blocks/mobile-features';
import Awards from 'components/globals/awards';
import Yoast from 'components/globals/yoast';
import { HeroHome } from 'components/hero/home';

import '../styles/cap.scss';

const CapScheduling = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "cap-scheduling" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              hero {
                title
                sub_title
                copy
                background_desktop_image {
                  source_url
                }
                background_desktop_image_2 {
                  source_url
                }
                signup {
                  launch_modal_button_text
                  modal_title
                  modal_submit_button_text
                  unique_id
                  legal_text
                  layout
                }
              }
              features_grid {
                title
                sub_title
                buttons {
                  title
                  body
                  alt_text
                  icon_width
                  icon_from_library
                  mobile_screenshot {
                    source_url
                  }
                  desktop_screenshot {
                    source_url
                  }
                }
              }
              features_grid_mobile_1 {
                title
                sub_title
                buttons {
                  button_1 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_2 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_3 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_4 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                }
              }
              capterra_reviews {
                title
                sub_title
                reviews {
                  review {
                    name
                    title
                    quote
                    review
                    capterra_logo {
                      source_url
                    }
                  }
                }
              }
            }
          }
        }
      }
      allWordpressAcfOptions {
        edges {
          node {
            options {
              industries {
                industries {
                  url
                  title
                  icon {
                    alt_text
                    source_url
                  }
                }
              }
              awards {
                background_image_desktop {
                  url {
                    source_url
                  }
                }
                background_image_mobile {
                  url {
                    source_url
                  }
                }
                content
                sub_title
                title
                main_image_desktop {
                  url {
                    source_url
                  }
                }
                main_image_mobile {
                  url {
                    source_url
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;
  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const hero = pageContext.hero;
  const awards = data.allWordpressAcfOptions.edges[0].node.options.awards;
  const featuresGrid = pageContext.features_grid;
  const mobileFeatures = pageContext.features_grid_mobile_1;
  const customerReviews = pageContext.capterra_reviews;

  return (
    <Layout legalText={ hero.signup.legal_text }>
      <Yoast { ...yoast } />
      <div className="lp-cap-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroHome
          image_height={ 469 }
          image_width={ 782 }
          num_logos={ 28 }
          source_url="https://marketing-assets.wheniwork-production.com/2019/10/14154206/sprite_customers_trans.png"
          { ...hero }
        />
        <Awards { ...awards } category="scheduling" />
        <FeaturesGrid { ...featuresGrid[0] } />
        <MobileFeatures { ...mobileFeatures } />
        <CustomerReviews { ...customerReviews } />
      </div>
    </Layout>
  );
};

export default CapScheduling;
